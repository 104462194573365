import React, { useState } from 'react';
import Image from 'next/legacy/image';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { LANGUAGE_CODE } from 'src/constants';
import { CustomLink } from '@components/ui';
import useSettings from '@hooks/useSettings';
import { PATH_FAQ, PATH_PAGE } from 'src/routes/paths';
import { CustomIcon } from '@components/ui/Icon';
import RecommendLanguage from './RecommendLanguage';
import {
  CenterWrap,
  CompanyInfo,
  CompanyInfoLine,
  CompanyInfoText,
  Container,
  Copyright,
  Global,
  GlobalSelect,
  InfoWrap,
  Logo,
  Menu,
  MenuText,
  Navi,
  Option,
  SelectWrapper,
  Social,
} from './styled';
import { useAdultCertification } from '@hooks/useAdultCertifiation';

const Styled = {
  ChatBox: styled.div<{ isShowRecommendLanguage: boolean; isVisible: boolean }>`
    position: fixed;
    z-index: 2147483100;
    bottom: ${(props) => (props.isShowRecommendLanguage ? 84 : 20)}px;
    right: 20px;
    display: ${(props) => (props.isVisible ? 'none' : 'block')};
  `,
};

export const Footer: React.FC = () => {
  const { checkRefreshAdultCertification } = useAdultCertification();
  const [isShowRecommendLanguage, setIsShowRecommendLanguage] = useState<boolean>(false);

  const { t, i18n } = useTranslation(['footer']);
  const { themeMode } = useSettings();
  const router = useRouter();
  // 언어 변경 이벤트
  const changeLanguage = async (e) => {
    const locale = e.target.value;
    await checkRefreshAdultCertification(locale);
    router.replace(router.asPath, router.asPath, { locale: locale });
  };

  // 사업자 번호 정보 조회하기 클릭 이벤트 처리기 메소드
  const handleBizInfoClick = () => {
    // 사업자 등록번호 코드
    const code = 3648701374;
    // window open
    const win = window.open(
      `http://www.ftc.go.kr/info/bizinfo/communicationViewPopup.jsp?wrkr_no=${code}`,
      'communicationViewPopup',
      'width=750,height=700,resizable=no,scrollbars=no',
    );
    win.focus();
    // window 반환
    return win;
  };

  // 언어별로 Footer 정보 세팅
  const languageCompanyInfo = (() => {
    // 한국 몰 Footer 정보 세팅
    if (i18n.language === LANGUAGE_CODE.KO) {
      return (
        <CompanyInfo>
          <CompanyInfoLine>
            <CompanyInfoText>{t('footer:copyright.company')}</CompanyInfoText>
            <CompanyInfoText>{t('footer:copyright.representative')}</CompanyInfoText>
            <CompanyInfoText>{t('footer:copyright.address')}</CompanyInfoText>
            <CompanyInfoText>{t('footer:copyright.morn')}</CompanyInfoText>
            <CompanyInfoText>{t('footer:copyright.brn')}</CompanyInfoText>
            <CompanyInfoText className={'cursor-pointer'} onClick={handleBizInfoClick}>
              <a>{t('footer:copyright.bic')}</a>
            </CompanyInfoText>
          </CompanyInfoLine>
          <CompanyInfoLine>
            <CompanyInfoText>{t('footer:copyright.pim')}</CompanyInfoText>
            <CompanyInfoText>번호(발신전용): 02-878-1201</CompanyInfoText>
            <CompanyInfoText>이메일: cs@acon3d.com</CompanyInfoText>
            <CompanyInfoText>{t('footer:copyright.hosting')}</CompanyInfoText>
          </CompanyInfoLine>
          <CompanyInfoLine>
            <CompanyInfoText>copyright (c) www.acon3d.com all rights reserved.</CompanyInfoText>
          </CompanyInfoLine>
        </CompanyInfo>
      );
    }

    // 글로벌 몰 Footer 정보 세팅
    return (
      <CompanyInfo>
        <CompanyInfoLine>
          <CompanyInfoText>{t('footer:copyright.company')}</CompanyInfoText>
          <CompanyInfoText>{t('footer:copyright.address')}</CompanyInfoText>
          <CompanyInfoText>{t('footer:copyright.representative')}</CompanyInfoText>
        </CompanyInfoLine>
        <CompanyInfoLine>
          <CompanyInfoText>
            {t('footer:copyright.brn')}
            <a href="#" target="_blank" onClick={handleBizInfoClick}>
              {t('footer:copyright.bic')}
            </a>
          </CompanyInfoText>
          <CompanyInfoText>{t('footer:copyright.morn')}</CompanyInfoText>
        </CompanyInfoLine>
        <CompanyInfoLine>
          <CompanyInfoText>{t('footer:copyright.pim')}</CompanyInfoText>
          <CompanyInfoText>Contact: cs@acon3d.com</CompanyInfoText>
          <CompanyInfoText>{t('footer:copyright.hosting')}</CompanyInfoText>
          <CompanyInfoText>copyright (c) www.acon3d.com all rights reserved.</CompanyInfoText>
        </CompanyInfoLine>
      </CompanyInfo>
    );
  })();

  return (
    <>
      <Styled.ChatBox isShowRecommendLanguage={isShowRecommendLanguage} isVisible={router.pathname === '/intro/3d-creator'}>
        <CustomLink href={PATH_FAQ.root}>
          <CustomIcon src="/icons/chatbox.png" alt="chatbox" title="chatbox" width="60" height="60" />
        </CustomLink>
      </Styled.ChatBox>
      <RecommendLanguage isShow={isShowRecommendLanguage} setIsShow={setIsShowRecommendLanguage} />
      <Container>
        <Navi>
          <CenterWrap>
            <Menu>
              <MenuText>
                <CustomLink href="/policy/terms-of-use" newWindow>
                  {t('footer:tos')}
                </CustomLink>
              </MenuText>
              <MenuText weight={700}>
                <CustomLink href="/policy/privacy" newWindow>
                  {t('footer:policy')}
                </CustomLink>
              </MenuText>
              <MenuText>
                <CustomLink href={PATH_PAGE.faqCreator}>{t('footer:upload')}</CustomLink>
              </MenuText>
              <MenuText>
                <CustomLink href={PATH_FAQ.root}>{t('footer:cs')}</CustomLink>
              </MenuText>
              {i18n.language === LANGUAGE_CODE.KO && (
                <>
                  <MenuText>
                    <CustomLink href={'https://www.notion.so/acon3d/8ea4db50bd6b472dab17b94316e850e3'} newWindow={true}>
                      {'채용정보'}
                    </CustomLink>
                  </MenuText>
                  {/* 공지사항 버튼 추가 220715 */}
                  <MenuText>
                    <CustomLink href={'https://acon3d.notion.site/05880402c6764fc6ae3f975bfd0f9cd7?v=52dc73ed1989485b903cac5e75d20fa3'} newWindow={true}>
                      {'공지사항'}
                    </CustomLink>
                  </MenuText>
                </>
              )}
            </Menu>
            <Option>
              <Social>
                {i18n.language === LANGUAGE_CODE.KO && (
                  <a href="https://pf.kakao.com/_TDFsT" target="_blank" rel="noreferrer">
                    <Image src="/icons/footer/kakao-icon.svg" alt="kakao-channel" title="kakao-channel" width="20" height="20" />
                  </a>
                )}
                {i18n.language === LANGUAGE_CODE.CN && (
                  <a href="https://www.weibo.com/acon3dofficial" target="_blank" rel="noreferrer">
                    <Image src="/icons/footer/weibo-icon.png" alt="weibo" title="weibo" width="20" height="20" />
                  </a>
                )}
                <a href={t('footer:instaLink')} target="_blank" rel="noreferrer">
                  <Image src="/icons/footer/insta-icon.svg" alt="instagram" title="instagram" width="20" height="20" />
                </a>
                <a href={t('footer:twitterLink')} target="_blank" rel="noreferrer">
                  <Image src="/icons/footer/twitter-icon.svg" alt="twitter" title="twitter" width="20" height="20" />
                </a>
                <a href={t('footer:facebookLink')} target="_blank" rel="noreferrer">
                  <Image src="/icons/footer/facebook-icon.svg" alt="facebook" title="facebook" width="20" height="20" />
                </a>
              </Social>
              <Global>
                <Image src="/icons/footer/global-icon.svg" alt="global" title="global" width="18" height="18" />
                <SelectWrapper>
                  <GlobalSelect value={i18n.language} onChange={changeLanguage}>
                    <option value={LANGUAGE_CODE.KO}>한국어</option>
                    <option value={LANGUAGE_CODE.EN}>English</option>
                    <option value={LANGUAGE_CODE.CN} disabled={themeMode === 'game'}>
                      中文
                    </option>
                    <option value={LANGUAGE_CODE.JP} disabled={themeMode === 'game'}>
                      日本語
                    </option>
                  </GlobalSelect>
                </SelectWrapper>
              </Global>
            </Option>
          </CenterWrap>
        </Navi>
        <Copyright>
          <CenterWrap>
            <InfoWrap>
              <Logo>
                <Image src="/icons/footer/footer-logo-icon.svg" alt="global" title="global" width="60" height="16" />
              </Logo>
              {/* 언어별로 Footer 정보 출력 */}
              {languageCompanyInfo}
            </InfoWrap>
          </CenterWrap>
        </Copyright>
      </Container>
    </>
  );
};
